import '../../node_modules/normalize.css/normalize.css';
import React from "react";
import {Helmet} from "react-helmet";
import {knuth_shuffle} from '../helpers/main';
import {Main} from '../components/Main';
import {Header} from '../components/Header';
import {Footer} from '../components/Footer';
import {Landing} from '../components/Landing';

export default class Index extends React.Component {
    constructor(props) {
        super(props);
    }

    deviceOrientation = null;
    count = 0;
    state = {
        currentYear: new Date().getFullYear(),
        colors: [
            "#003399", // Blue
            "#CD3333", // Red
            "#FFCC33"  // Yellow
        ]
    };

    componentDidMount() {
        if (window.DeviceOrientationEvent) {
            window.addEventListener(
                "deviceorientation",
                this.handleDeviceorientation,
                true
            );
        }
    }

    handleDeviceorientation = (e) => {
        console.log('motion')
        const alpha = Math.floor(e.alpha);
        const beta = Math.floor(e.beta);
        const gamma = Math.floor(e.gamma);
        const deviceOrientation = alpha + beta + gamma;
        if (this.deviceOrientation !== deviceOrientation) {
            this.shuffleColors();
        }
        this.deviceOrientation = deviceOrientation;
    }

    handleMousemove = () => {
        this.shuffleColors();
    }

    shuffleColors = () => {
        if (this.count > 1) {
            this.setState({
                colors: knuth_shuffle(this.state.colors)
            });
            this.count = 0;
        } else {
            this.count = this.count + 0.2;
        }
    }

    render() {
        return (
            <Main
                backgroundColor={this.state.colors[0]}
                onMouseMove={this.handleMousemove}>
                <Helmet>
                    <title>Tevincocin</title>
                    <link rel="apple-touch-icon" sizes="180x180" href="images/favicons/apple-touch-icon.png"/>
                    <link rel="icon" type="image/png" href="images/favicons/favicon-32x32.png" sizes="32x32"/>
                    <link rel="icon" type="image/png" href="images/favicons/favicon-16x16.png" sizes="16x16"/>
                    <link rel="manifest" href="manifest.json"/>
                    <link rel="mask-icon" href="images/favicons/safari-pinned-tab.svg" color="#757575"/>
                    <link rel="shortcut icon" href="images/favicons/favicon.ico"/>
                    <meta name="msapplication-config" content="images/favicons/browserconfig.xml"/>
                    <meta name="theme-color" content="#ffffff"/>
                    <meta name="p:domain_verify" content="b3701ec5345cc65c4ee11e4d2b68618c"/>
                </Helmet>
                <Header></Header>
                <Landing colors={this.state.colors} />
                <Footer>
                <p
                    style={{
                        color: this.state.colors[2]
                    }}>Tevincocin&trade;, TVNCCN&trade;, <span>{this.state.currentYear}</span>&copy;</p>
                </Footer>
            </Main>
        );
    }
}
